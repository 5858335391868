import nmsp from 'nmsp';

class Init {

    constructor() {

        this.createNamespace();

    }

    createNamespace() {

        Object.defineProperty( window, 'FRBP', {
            value: nmsp( {
                components: []
            } )
        } );

    }

}

new Init;